<template>
  <section>
    <div class="page-header">
      <h3 class="page-title">
        Map Price Package
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link class="nav-link" to="/"><span class="menu-title">Dashboard</span></router-link></li>
          <li class="breadcrumb-item">Operasional</li>          
          <li class="breadcrumb-item active" aria-current="page">Map Price Package</li>
        </ol>
      </nav>
    </div>
    <div class="row" ref="containerMain">
      <div class="col-lg-12 grid-margin stretch-card">
				<div class="card">
					<b-modal
						:centered="true"
						:hide-footer="true"
						:title="titleModal"
						v-model="modalForm"
					>
						<b-row>
							<b-col class="mb-3" lg="12">
                <b-row>
                  <b-col lg="6"><label>File Excel</label></b-col>
                  <b-col lg="6" style="text-align: right;"><label>* required</label></b-col>                                       
                </b-row>
                <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
							</b-col> 
							<b-col class="mb-3 mb-xl-0" lg="12">
								<b-input-group>
									<b-button
										variant="primary"
										href="javascript:void(0)"
										@click="sendPayload()">Save
									</b-button>
								</b-input-group>
							</b-col>                
						</b-row>
					</b-modal>					
					<div class="card-body">
						<b-form-group
							label="Filter"
							label-for="filter-input"
							label-cols-md="3"
							label-align-md="right"
							label-size="md"
							class="mb-1"
						>
							<b-input-group size="md">
								<b-form-input
									id="filter-input"
									type="search"
									placeholder="Type to Search"
									style="margin-right: 0.5rem"
								></b-form-input>

								<b-input-group-append>
									<b-button
										style="margin-right: 0.5rem"
										>Search</b-button
									>
									<b-button >Clear</b-button
									>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>							
					</div>
				</div>
      </div>

      <div class="col-lg-12 grid-margin stretch-card">
				<div class="card">
					<div class="card-head">
						<div class="row col-lg-12 pull-right float-none float-sm-right d-block mt-3 mt-sm-1 text-right mr-1 pt-2 pl-2">
							<button type="button"  
								class="btn btn-sm btn-primary btn-icon-text"
								@click="openImportModal()">
								Import
								<i class="mdi mdi mdi mdi-plus-circle-outline btn-icon-append"></i>
							</button>							
						</div>
					</div>
					<div class="card-body">
							<b-table 
							responsive 
							show-empty
							:striped="tableConfig.striped"
							:bordered="tableConfig.bordered"
							:borderless="tableConfig.borderless"
							:outlined="tableConfig.outlined"
							:small="tableConfig.small"
							:hover="tableConfig.hover"
							:dark="tableConfig.dark"
							:fixed="tableConfig.fixed"
							:foot-clone="tableConfig.footClone"
							:no-border-collapse="tableConfig.noCollapse"
							:head-variant="tableConfig.headVariant"
							:table-variant="tableConfig.tableVariant"
							:items="data" 
							:fields="fields">
								<template #cell(index)="data">
									{{ data.index + 1 }}
								</template>		
								<template v-slot:cell(origin)="row">
                  <b>{{ row.item['Kab/City'] }} {{ row.item['Kab/City_Name']}} </b>
                  ID_Kab : {{ row.item['ID_Kab']}}, 
                  <b>{{ row.item['Kec/Sub_District']}}</b>
                  ID_Kec :{{ row.item['ID_Kec']}}, 
                  <b>{{ row.item['Kelurahan/Village']}}</b>
                  ID_Kel : {{ row.item['ID_Kel'] }}, 
                  <b>Kode Pos : {{ row.item['Kode_Pos'] }}</b>
								</template>
								<template v-slot:cell(destination)="row">
                  <b>{{ row.item['Kab/City_1'] }} {{ row.item['Kab/City_Name_1']}} </b>
                  ID_Kab : {{ row.item['ID_Kab_1']}}, 
                  <b>{{ row.item['Kec/Sub_District_1']}}</b>
                  ID_Kec :{{ row.item['ID_Kec_1']}}, 
                  <b>{{ row.item['Kelurahan/Village_1']}}</b>
                  ID_Kel : {{ row.item['ID_Kel_1'] }}, 
                  <b>Kode Pos : {{ row.item['Kode_Pos_1'] }}</b>
								</template>                                
								<template v-slot:cell(created_at)="row">
									{{ normalDateTime(row.item.created_at) }}
								</template>                				
								<template v-slot:cell(time_unit_name)="row">
									{{ row.item.value }} {{ row.item.time_unit_name }}
								</template>
								<template v-slot:cell(updated_at)="row">
									{{ normalDateTime(row.item.updated_at) }}
								</template>
								<!-- <template v-slot:cell(actions)="row">
									<button type="button"  
										class="btn btn-sm btn-warning btn-icon-text"
										@click="openEditModal(row.item.uuid, row.item)">
										Edit
										<i class="mdi mdi-file-check btn-icon-append"></i>
									</button>
									<br>
									<br>

									<button type="button"  
										class="btn btn-sm btn-danger btn-icon-text"
										@click="openDeleteModal(row.item.uuid, row.item)">
										Delete
										<i class="mdi mdi mdi-delete-variant btn-icon-append"></i>
									</button>
									<br>
								</template>							 -->
							</b-table>
							<b-pagination
								v-model="page"
								:total-rows="totalRows"
								:per-page="limit"
								align="fill"
								size="md"
								class="mb-1"
								v-on:click.native="callback"
							></b-pagination>
              <b-row class="pt-4">
                <p class="col-lg-12 mb-1">Total Data : {{ data.length }}</p>                
                <p class="col-lg-12 mb-1">Total Data per Page : {{ totalRows }}</p>              
                <p class="col-lg-12 mb-1">Total Page : {{ lastPage }}</p>
              </b-row>              
					</div>
				</div>
      </div>			
    </div>
  </section>

</template>

<script>
import axios from "axios"
import packageStore from "@/packages/stores"
import packageGlobals from "@/packages/globals"
export default {
  name: 'basicTables',
  data () {
    return {
      titleModal: "",
      modalForm: false,
      stateCrud: "",			
			tableConfig : packageGlobals.table,
      data: [],
      dataOption1: [],
      optSelect1: '',      
      totalRows: null,
      lastPage: null,
      page: 1,
      limit: 10,
      filter: null,
			idKey: null,
      form: {
				fullname: "",
        shortname: "",
        email: "",
        handphone: ""
      },	
      formUpload: {},      					
      fields: [
        {key: "index", label: "No.", sortable: true, class: "text-center" },
				{key: 'origin', label: 'Origin', class: "text-center", sortable: true},                
				{key: 'ID_KEBUT', label: 'ID_KEBUT', class: "text-center", sortable: true},        
				{key: 'destination', label: 'Destination', class: "text-center", sortable: true},        
				{key: 'ID_KEBUT_1', label: 'ID_KEBUT_1', class: "text-center", sortable: true},
        {key: '0,01-74,9kg', label: '0,01-74,9kg', class: "text-center", sortable: true},
        {key: "75-249,9kg",label: "75-249,9kg",sortable: true,class: "text-center"},
        {key: "250-499,9kg",label: "250-499,9kg",sortable: true,class: "text-center"},
        {key: "500-1000kg",label: "500-1000kg",sortable: true,class: "text-center"},        				
				{key: 'actions',label: 'Action',class: "text-center",sortable: true}
      ],
			data404 : []
    }
  },
	methods: {
    normalDateTime: (dateData) => packageGlobals.normalDateTime(dateData),
		responseAxiosError: (error) => packageGlobals.responseAxiosError(error),    
    callback: function (page) {
      this.$router.push({ query: { page: page.target.ariaPosInSet } })
      var currenPages = page.target.ariaPosInSet == null ? 1 : page.target.ariaPosInSet
      this.getAll('-', currenPages, this.limit)
    },		
		handleFileUpload(){			
			this.formUpload.file = this.$refs.file.files[0];			
		},    
    getAll: function (search, page, limit) {
      var loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.containerMain,
        canCancel: false,
        onCancel: this.onCancel,
      })

      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/operational/map-price?search=${search}&page=${page}&limit=${limit}`,packageGlobals.configAxios)
			.then((response) => {
        this.data = response.data.result.data
        this.totalRows = response.data.result.total
        this.lastPage = response.data.result.lastPage
        loader.hide()
			})				
      .catch(function () {
        // this.responseAxiosError(error)
        loader.hide();        
      })	
    },
    searchData: function() {
      this.getAll(this.search,this.page, this.limit)      
    },
    openImportModal: function () {
      this.form.name = ""
      this.form.remarks = ""      
      this.titleModal = "Import Data"
      this.stateCrud = "import"
      this.modalForm = true
    },
    openAddModal: function () {
      this.form.name = ""
      this.form.remarks = ""      
      this.titleModal = "Import"
      this.stateCrud = "add"
      this.modalForm = true
    },    
    openEditModal: function (id, item) {
      this.titleModal = "Edit Data"
      this.stateCrud = "edit"
      this.idKey = id
      this.form.name = item.name
      this.form.remarks = item.remarks      
      this.modalForm = true
    },
    openDeleteModal: function (id, item) {
      this.$swal.fire({
        title: "Are you sure you want to delete this data?",
        text: `Data : ${item.name}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete!",
      })
      .then((result) => {
        var loader = this.$loading.show({
          container: this.fullPage ? null : this.$refs.containerMain,
          canCancel: false,
          onCancel: this.onCancel,
        })
        if (result.value) {
          packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "DELETE"
          axios.delete(`${packageStore.mainApi}/api/web/master/courier/${id}/${localStorage.getItem("uuid")}`)
          .then((response) => {
            this.responseAxios(response)
            loader.hide()
          })				
          .catch(function (error) {
            this.responseAxiosError(error)
            loader.hide();        
          })
        }
      })
    },		
    sendPayload: function () {
      if (this.stateCrud != "import") {
        let checkField = packageGlobals.checkField(
          [
            {value : this.form.fullname, message : 'field fullname is required', key:'fullname'},
            {value : this.form.shortname, message : 'field shortname is required', key:'shortname'},
            {value : this.form.email, message : 'field email is required', key:'email'},
            {value : this.form.telp, message : 'field telp is required', key:'telp'},                              
          ]
        )
        var flag = checkField.flag, message = checkField.message
        if (flag == 0) {
          var loader = this.$loading.show({
            container: this.fullPage ? null : this.$refs.containerMain,
            canCancel: false,
            onCancel: this.onCancel,
          })

          this.form.uuid_delivery_types = this.optSelect1['id']        
          if (this.stateCrud == "add") {
            this.created_by = localStorage.getItem("uuid")
            packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "POST"
            // axios.post(`${packageStore.mainApi}/api/web/master/courier`,this.form,packageGlobals.configAxios)					
            axios.post(`${packageStore.mainApi}/api/web/master/courier`,this.form)
            .then((response) => {
              this.responseAxios(response)
              loader.hide()
            })				
            .catch(function (error) {
              this.responseAxiosError(error)
              loader.hide();        
            })
          } else if (this.stateCrud == "edit") {
            this.updated_by = localStorage.getItem("uuid")					
            packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "PUT"
            axios.put(`${packageStore.mainApi}/api/web/master/courier/${this.idKey}`,this.form)
            .then((response) => {
              this.responseAxios(response)
              loader.hide()
            })				
            .catch(function (error) {
              this.responseAxiosError(error)
              loader.hide();        
            })
          }
          // loader.hide()
        }
        else
        {
          this.$swal.fire({
            title: "Ups, There is a mistake",
            text: message,
            icon: "error",
            confirmButtonText: "Ok",
          });        
        }              
      }
      else
      {
        let formData = new FormData();
          formData.append('file_excel', this.formUpload.file);          
          packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "POST"
          // this.configAxios.headers["Content-Type"] = "multipart/form-data";
          axios.post(`${packageStore.mainApi}/api/web/operasional/map-price/import/`,formData)
          .then((response) => {
            this.responseAxios(response)
          })        
      }

    },
    responseAxios: function (response) {
      if (response.data.status == 200) {
        this.modalForm = false
        this.$swal.fire({
          title: "Success",
          text: response.data.result.message,
          icon: "success",
          confirmButtonText: "Ok",
        })
				this.getAll('-', this.page, this.limit)	
      } else {
        switch (response.data.status) {
          case 401:
            this.$swal.fire({
              title: "Ups, There is a mistake",
              text: response.data.result[0].message,
              icon: "error",
              confirmButtonText: "Ok",
            })
            break
          default:
            break
        }
      }
    },				
	},
	mounted() {
		this.getAll('-', this.page, this.limit)
	},
}
</script>
<style>
.breadcrumb-item + .breadcrumb-item::before
{
	padding-top: 1px;
}

.breadcrumb-item + .breadcrumb-item
{
	padding-top: 8px;
}

.table.table-borderless tr, .table.table-borderless td, .table.table-borderless th
{
  white-space: pre-wrap;
}
</style>